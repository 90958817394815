import { Injectable } from '@angular/core';
import {
  BiometricAuth,
  BiometryErrorType,
  CheckBiometryResult,
} from '@aparajita/capacitor-biometric-auth';
import { from, Observable } from 'rxjs';

@Injectable()
export class BiometricsService {
  checkBiometrics(): Observable<CheckBiometryResult> {
    return from(BiometricAuth.checkBiometry());
  }

  authenticate(): Observable<void | BiometryErrorType> {
    return from(
      BiometricAuth.authenticate({
        reason: 'Please authenticate through fingerprint',
        cancelTitle: 'Proceed Manually',
        allowDeviceCredential: false,
        iosFallbackTitle: 'Use passcode',
        androidTitle: 'Authenticate',
        androidSubtitle: 'Biometric Authentication',
        androidConfirmationRequired: false,
      })
    );
  }
}
